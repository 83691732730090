<script>
    import { faQuestion } from '@fortawesome/free-solid-svg-icons'
    import Fa from 'svelte-fa'
    import { onMount } from 'svelte'
    import { _ } from 'svelte-i18n'
    import PrivacyPolicyLink from '$lib/Components/Heading/Modals/PrivacyPolicyLink.svelte'
    import FaqLink from '$lib/Components/Heading/Modals/FaqLink.svelte'

    let modal

    // Bs javascript relies on document being available. Dynamically import it onMount,
    // so we know for sure that we are in the browser and not in SSR
    onMount(async () => {
        const { Modal } = await import('bootstrap')
        modal = new Modal(document.getElementById('info-modal'))
    })
</script>

<button type="button" class="infobutton fade-in btn border-1" on:click={() => modal.show?.()}>
    <Fa icon={faQuestion} />
</button>

<div class="modal fade" id="info-modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{$_('title.mobile_payment')}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body py-5 text-center">
                <p>
                    {$_('payment.message.mobile_payment_info1')}
                </p>

                <p>
                    {$_('payment.paybycall.help.message.info2')}
                    {$_('payment.message.mobile_payment_info2')}
                    {$_('payment.message.help_links_title')}:
                </p>

                <p>
                    <FaqLink />
                    <br />
                    <PrivacyPolicyLink />
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal"
                    >{$_('word.close_element')}</button
                >
            </div>
        </div>
    </div>
</div>
