<script>
    import { _ } from 'svelte-i18n'
    import { branding } from '$lib/stores/index.js'

    $: brandedFaqLink = $branding?.urls?.['faq']
</script>

<a target="_blank" href={brandedFaqLink || `https://www.sirumobile.com/faq`} class="text-decoration-none">
    <strong>
        {$_('word.faq')}
    </strong>
</a>
