<script>
    import { _ } from 'svelte-i18n'
    import { fade } from 'svelte/transition'
    import { alerts } from '$lib/stores/index.js'
</script>

{#each $alerts as { severity, message }, i}
    <div class="alert-container text-center" transition:fade>
        <div class={`alert alert-dismissible alert-${severity}`} role="alert">
            {$_(message)}
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                on:click={() => alerts.clear(i)}
            />
        </div>
    </div>
{/each}

<style>
    .alert-container {
        z-index: 9000;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        opacity: 0.93;
    }
</style>
