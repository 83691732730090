<script>
    import '$lib/styles/app.scss'
    import Heading from '$lib/Components/Heading/Heading.svelte'
    import Alerts from '$lib/Components/Alerts.svelte'
    import { error } from '$lib/stores'
    import Error from '$lib/Components/Error/Error.svelte'
    import Footer from '$lib/Components/Footer.svelte'
    import { PUBLIC_GOOGLE_TAG_MANAGER_TRACKING_ID } from '$env/static/public'
    import { createGtmSrc } from '$lib/utilities/tagmanagerTools.js'
    import { onMount } from 'svelte'

    // Add gtag function to window
    onMount(() => {
        window.dataLayer = window.dataLayer || []
        function gtag() {
            window.dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', PUBLIC_GOOGLE_TAG_MANAGER_TRACKING_ID)
        window.gtag = gtag
    })
</script>

<svelte:head>
    {#if PUBLIC_GOOGLE_TAG_MANAGER_TRACKING_ID}
        <script src={createGtmSrc()}></script>
    {/if}
</svelte:head>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8 col-md-10">
            <Heading />

            <Alerts />

            <div class="card pt-4 pb-3">
                <main class="mb-2">
                    {#if $error}
                        <Error status={$error.status} code={$error.code} />
                    {:else}
                        <slot />
                    {/if}
                </main>
            </div>
            <Footer />
        </div>
    </div>
</div>

<style lang="scss">
    .card {
        box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 0.5em;

        background-color: white;
        text-align: center;

        @media (min-width: 576px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @media (max-width: 575px) {
            padding-left: 25px;
            padding-right: 25px;
            margin-left: -15px;
            margin-right: -15px;
            box-shadow: none;
            border-radius: 0;
            border: none;
            -webkit-box-shadow: none;
        }
    }

    footer a {
        text-decoration: none;
    }
    footer a:hover {
        text-decoration: underline;
    }
</style>
