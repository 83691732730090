import {
    PUBLIC_GOOGLE_TAG_MANAGER_TRACKING_ID,
    PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW,
    PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
} from '$env/static/public'

export const createGtmSrc = () => {
    let gtmSrc = `https://www.googletagmanager.com/gtm.js?id=${PUBLIC_GOOGLE_TAG_MANAGER_TRACKING_ID}`

    if (PUBLIC_GOOGLE_TAG_MANAGER_AUTH) {
        gtmSrc += `&gtm_auth=${PUBLIC_GOOGLE_TAG_MANAGER_AUTH}`
    }

    if (PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW) {
        gtmSrc += `&gtm_preview=${PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW}`
    }

    return gtmSrc
}
