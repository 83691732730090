import type { LayoutLoad } from './$types'
import { locale, waitLocale } from 'svelte-i18n'
import { browser } from '$app/environment'
import { getLanguageFromBCP47, supportedLocales } from '$lib/i18n'

export const load: LayoutLoad = async () => {
    locale.set('en')

    if (browser) {
        const browserLanguage = getLanguageFromBCP47(window.navigator.language)
        if (supportedLocales.includes(browserLanguage)) {
            locale.set(browserLanguage)
        }
    }

    await waitLocale()
}
