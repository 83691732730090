<script>
    import SiruLogo from '$lib/images/sirumobile-logo.png'
    import { branding, fetching, payment } from '$lib/stores'
    import Fa from 'svelte-fa'
    import V1InfoButtonAndModal from '$lib/Components/Heading/Modals/V1InfoButtonAndModal.svelte'
    import V2InfoButtonAndModal from '$lib/Components/Heading/Modals/V2InfoButtonAndModal.svelte'
    import V3InfoButtonAndModal from '$lib/Components/Heading/Modals/V3InfoButtonAndModal.svelte'
    import V4InfoButtonAndModal from '$lib/Components/Heading/Modals/V4InfoButtonAndModal.svelte'
    import { get } from 'lodash-es'

    $: logo = $branding?.logo || SiruLogo
    $: loading = get($fetching, `payment.fetching`, true)
</script>

<div class="heading row my-2">
    <div class="col-sm-7 col-5 text-start ml-0">
        {#if !loading}
            <img src={logo} class="logo fade-in" alt="Siru Mobile" />
        {/if}
    </div>
    <div class="col-sm-5 col-7 text-end">
        {#if $payment.variant === 'variant1'}
            <V1InfoButtonAndModal />
        {:else if $payment.variant === 'variant2'}
            <V2InfoButtonAndModal />
        {:else if $payment.variant === 'variant3'}
            <V3InfoButtonAndModal />
        {:else if $payment.variant === 'variant4'}
            <V4InfoButtonAndModal />
        {/if}
    </div>
</div>

<style lang="scss">
    .text-start {
        height: 30px;
    }
    img.logo {
        height: 30px;
    }

    .text-start {
        font-size: 1.4em;
    }
</style>
