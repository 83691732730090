<script>
    import { _ } from 'svelte-i18n'
    import { branding } from '$lib/stores/index.js'

    $: brandedPPLink = $branding?.urls?.['privacy-policy']
</script>

<a href={brandedPPLink || 'https://www.sirumobile.com/privacy-policy'} target="_blank" class="text-decoration-none">
    {$_('phrase.privacy_policy')}
</a>
