<script>
    import { _ } from 'svelte-i18n'
    import DCBLogo from '$lib/images/payment/ip/mobiilimaksu_logo-black.png'
    import { branding, payment } from '$lib/stores/index.js'

    $: brandedTCLink = $branding?.urls?.['terms-and-conditions']
    $: displayDCBLogo = $payment?.variant === 'variant2'
</script>

<footer class="py-4 text-center text-secondary small">
    {#if displayDCBLogo}
        <div class="dcblogo-container">
            <img src={DCBLogo} alt={$_('title.mobile_payment')} />
        </div>
    {/if}
    Powered by Siru Mobile -
    <a
        href={brandedTCLink || 'https://www.sirumobile.com/terms-and-conditions'}
        class="text-secondary"
        target="_blank"
        rel="noopener">{$_('phrase.terms_and_conditions')}</a
    >
</footer>

<style lang="scss">
    @keyframes fade-and-stretch {
        100% {
            height: 42px;
            opacity: 1;
        }
    }

    .dcblogo-container > img {
        height: 0;
        opacity: 0;
        animation: fade-and-stretch 150ms forwards;
    }
</style>
